import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FullscreenMessage from './FullscreenMessage';
import IssueVoucher from './IssueVoucher';
import Validation from './Validation';
import ShowVoucher from './ShowVoucher';
import { FormattedMessage, IntlProvider} from 'react-intl';
import en from "./intl/en.json";
import de from "./intl/de.json";
import jp from "./intl/jp.json";

class App extends React.Component {

  constructor(props) {
      super(props);
      this.state.lang = this.getLang();
   }

   state = {
     window:'',
   }

   componentDidMount() {
   }

   componentDidUpdate() {
   }

   getLang() {
     var lang = this.getNavigatorLanguage();
     if (lang.indexOf("-")>0) {
       lang = lang.substring(0,2);
     }
     if(lang==="en") return "en";
     if(lang==="de") return "de";
     return "en";
   }

   getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
   }

   trans( msgID) {
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }


   render() {
      const messages = {en,de,jp};

      var uri = window.location.pathname;
      var page = "";
      var code = "";
      if(uri.startsWith("/v/")) {
        code = uri.substring(uri.lastIndexOf("/")+1);
        page = "validate_voucher"
      }else if(uri.startsWith("/iv/")) {
        page = "issue_voucher";
      }else if(uri.startsWith("/sv/")) {
        code = uri.substring(uri.lastIndexOf("/")+1);
        page = "show_voucher";
      }

      //console.log("--->uri="+uri);
      return (
        <IntlProvider locale={this.state.lang} messages={messages[this.state.lang]}>
          {
            page==="issue_voucher"?<IssueVoucher/>:
            page==="validate_voucher"?<Validation code={code}/>:
            page==="show_voucher"?<ShowVoucher qr_code={code} />:
            <FullscreenMessage message={"invalid request"} />
          }
        </IntlProvider>
      );
   }

}

export default App;
