import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FullscreenMessage from './FullscreenMessage';
import VoucherView from './VoucherView';
import { FormattedMessage} from 'react-intl';

class IssueVoucher extends React.Component {

  constructor(props) {
      super(props);
   }

   state = {
     loaded:false,
     window:'',
     info_message:'',
     canvas_width:300,
     canvas_height:500,
     canvas:'',
     ctx:'',
     fullscreen_message:false,
     terms_conditions_content:'',
     validity_date:'',
     title:'',
     desc:'',
     image:undefined,
     qr_code:'',
     qr_width:250,
     qr_img:undefined,
   }

   componentDidMount() {
      this.loadVoucher();
   }

   componentDidUpdate() {
   }

   getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
   }

   translateDate( d) {
      var date = new Date(d);
			var options = { year: 'numeric', month: 'long', day: 'numeric', hour:"2-digit", minute:"2-digit" };
			return date.toLocaleString(this.getNavigatorLanguage(),options);
   }

   isNumber(n) {
     return !isNaN(parseFloat(n)) && isFinite(n);
   }

   getUserID() {
     var curUser = localStorage.getItem("etv.voucher_user_id");
     if(!curUser) {
       var userID = "etv_voucher_user_"+Math.floor(Math.random()*1000000000);
       console.log("user_id="+userID);
       localStorage.setItem("etv.voucher_user_id",userID);
     }
     return curUser;
   }


   loadVoucher() {
     var uri = window.location.pathname;
     var temp = uri.substring(uri.lastIndexOf("/iv/")+4);
     var locationID = temp.substring(temp.lastIndexOf("/")+1);
     var voucherDefID = temp.substring(0,temp.lastIndexOf("/"));
     if(locationID===null || locationID.length===0 || !this.isNumber(voucherDefID)) {
       this.showFullScreenMessage("location and voucher definintion must be defined");
       return;
     }
     var url = "https://elevatortvbackend.appspot.com/v?action=issue_voucher&type=json&voucher_def_id="+voucherDefID+"&location_id="+encodeURIComponent(locationID)+"&user_id="+this.getUserID();
     //console.log("url="+url);
     fetch(url)
         .then(res => res.json())
         .then(
           (result) => {
              //console.log(JSON.stringify(result));
              if (result["result"]===false) {
                this.showFullScreenMessage(result["reason"]);
                this.setState({loaded:true});
                return;
              }
              //console.log("voucherID="+result["voucher_id"]);
              this.setState({loaded:true,title:result["title"],desc:result["desc"],qr_code:result["voucher_id"],terms_conditions_content:result["terms"],validity_date:this.translateDate(result["validity"]),image:result["image"]});
           });
   }

   showFullScreenMessage(message) {
     this.setState({info_message:message,fullscreen_message:true});
   }

   trans( msgID) {
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   closeDialog() {
     this.setState({window:''});
   }

   saveCoupon() {
     var base64 = this.state.canvas.toDataURL('image/png');
     var link = document.createElement("a");
     document.body.appendChild(link);
     link.setAttribute("href", base64);
     link.setAttribute("download", "voucher.png");
     link.click();
   }

   render() {
      return (
        <div style={{margin:"5%",height:'90%',width:'90%'}}>
          {this.state.fullscreen_message? <FullscreenMessage message={this.state.info_message} />:
           !this.state.loaded?<span/>:
            <VoucherView qr_code={this.state.qr_code} desc={this.state.desc} title={this.state.title} validity_date={this.state.validity_date} terms_conditions_content={this.state.terms_conditions_content} />
          }
        </div>
      );
   }

}

export default IssueVoucher;
