import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FormattedMessage} from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FullscreenMessage from './FullscreenMessage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

class Validation extends React.Component {

  constructor(props) {
      super(props);
   }

   state = {
     window:'',
     code:'',
     terminal_id:'',
     error_message:'',
     validation_result:'',
     title:'',
     desc:'',
     message:'',
     used_time:'',
     validation_rx:false,
   }

   componentDidMount() {
     //console.log("validate voucher="+this.props.code);
     this.validateVoucher(this.props.code);
   }

   componentDidUpdate() {
   }

   showErrorMessage(msg) {
     this.setState({error_message:msg, window:'error_message'});
   }

   getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
   }

   translateDate( d) {
      var date = new Date(d);
			var options = { year: 'numeric', month: 'long', day: 'numeric', hour:"2-digit", minute:"2-digit" };
			return date.toLocaleString(this.getNavigatorLanguage(),options);
   }

   validateVoucher( code) {
     //localStorage.setItem("voucher_terminal","");
     var terminalID = localStorage.getItem("voucher_terminal");
     //console.log("terminalID="+terminalID);
     if(!terminalID || terminalID===null || terminalID.length===0) {
       this.setState({code:code,window:'set_terminal_id'});
       return;
     }
     var url = "https://elevatortvbackend.appspot.com/v?action=validate_voucher&voucher_id="+code+"&terminal_id="+terminalID;
     //console.log("url="+url);
     fetch(url)
         .then(res => res.json())
         .then(
           (result) => {
              //console.log(JSON.stringify(result));
              if (result["result"]===false) {
                  if(result["reason"]===5) {
                    this.setState({validation_rx:true,used_time:''});
                    this.showValidationResult(false,this.trans("error"),"",this.trans("coupon_does_not_exist"));
                    return;
                  }
                  if(result["reason"]===4) {
                    this.setState({validation_rx:true,used_time:''});
                    this.showValidationResult(false,result["voucher_def"].title,result["voucher_def"].content,this.trans("coupon_expired"));
                    return;
                  }
                  if(result["reason"]===3) {
                    this.setState({validation_rx:true,used_time:this.translateDate(result["reason_extra"])});
                    this.showValidationResult(false,result["voucher_def"].title,result["voucher_def"].content,this.trans("coupon_already_used"));
                    return;
                  }
                  if(result["reason"]===2) {
                    this.setState({validation_rx:true,window:'set_terminal_id'});
                    return;
                  }
                  if(result["reason"]===1) {
                    this.setState({validation_rx:true,used_time:''});
                    this.showErrorMessage("invalid_request");
                    return;
                  }
              }else{
                //console.log("show positive validation result");
                this.showValidationResult(true,result["voucher_def"].title,result["voucher_def"].content,this.trans("valid"));
                return;
              }
              // voucher ok
           });
     this.setState({code:code,window:"validation"});
   }

   setTerminalID() {
     //console.log("setting terminal ID="+this.state.terminal_id);
     localStorage.setItem("voucher_terminal",this.state.terminal_id);
   }

   trans( msgID) {
     //console.log("trans="+msgID);
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   refreshPage() {
     window.location.reload(false);
   }

   showValidationResult(result,title,desc,message) {
     this.setState({window:'validation',validation_rx:true,validation_result:result,title:title,desc:desc,message:message});
   }

   render() {
      return (
        <div style={{margin:"5%",height:'90%',width:'90%'}}>
          {
            this.state.window==='set_terminal_id'?
              <Card>
                <CardContent>
                    <Typography variant="h3">{this.trans("set_terminal_id")}</Typography>
                    <Box style={{width:400, marginTop:30}}>
                      <TextField style={{width:400}} InputProps={{style:{fontSize:40}}}  InputLabelProps={{ style:{fontSize:30} }} label="enter terminal ID" color="primary" focused onChange={(e)=>{this.setState({terminal_id:e.target.value})}} />
                    </Box>
                    <Button disabled={this.state.terminal_id.length===0} onClick={()=>{this.setTerminalID();this.refreshPage()}} style={{width:400,marginTop:30}} variant='contained' color='success' size="large" fullWidth={true}><Typography variant="h4">{this.trans("set_terminal_id")}</Typography></Button>
                </CardContent>
              </Card>
            :
            this.state.window==='validation'?
               !this.state.validation_rx?<span/>:
               <Card>
                 <CardContent>
                     <Card style={{backgroundColor: this.state.validation_result?"green":"red", color:'white'}}>
                        <CardContent>
                          <Typography style={{display:"flex",justifyContent:"center",fontWeight:"bold"}} variant="h2">{this.trans(this.state.validation_result?"valid":"invalid")}</Typography>
                          <Divider style={{marginTop:20,backgroundColor:'white'}} />
                          <Typography style={{marginTop:20,display:'flex',justifyContent:'center'}} variant="h3">{this.state.title}</Typography>
                          <Typography style={{marginTop:10,display:'flex',justifyContent:'center'}} variant="h4">{this.state.desc}</Typography>
                          <div style={{margin:"5%",height:'90%',width:'90%',display:"flex",justifyContent:"center"}}>
                            <Card style={{backgroundColor: "white", color:this.state.validation_result?"green":"red"}}>
                               <CardContent>
                                  {this.state.validation_result?
                                    <CheckCircleIcon style={{color:'green',fontSize:60}}/>
                                    :
                                    <span>
                                      <Typography variant="h5">{this.state.message}</Typography>
                                      <Divider style={{marginTop:20}}/>
                                      <Typography style={{marginTop:20,display:'flex',justifyContent:'center'}} variant="h5">{this.state.used_time}</Typography>
                                    </span>
                                  }
                               </CardContent>
                            </Card>
                          </div>
                        </CardContent>
                     </Card>
                 </CardContent>
                 <CardActions style={{justifyContent:'center'}}>
                    <Button onClick={()=>this.setState({window:'set_terminal_id'})} color="primary">{this.trans("set_terminal_id")}</Button>
                 </CardActions>
               </Card>
           :
           this.state.window==='error_message'?
              <FullscreenMessage message={this.state.error_message} />
           :
           <span/>
         }
        </div>
      );
   }

}

export default Validation;
