import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FormattedMessage} from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FullscreenMessage from './FullscreenMessage';
import VoucherView from './VoucherView';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

class ShowVoucher extends React.Component {

  constructor(props) {
      super(props);
   }

   state = {
     loaded:false,
     window:'',
     title:'',
     desc:'',
     qr_code:'',
     terms_conditions_content:'',
     validity_date:'',
     loaded:false,
   }

   componentDidMount() {
     this.showVoucher(this.props.qr_code);
   }

   componentDidUpdate() {
   }

   showErrorMessage(msg) {
     this.setState({error_message:msg, window:'error_message'});
   }

   getNavigatorLanguage() {
      //if (true) return "de";
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
   }

   translateDate( d) {
      var date = new Date(d);
			var options = { year: 'numeric', month: 'long', day: 'numeric', hour:"2-digit", minute:"2-digit" };
			return date.toLocaleString(this.getNavigatorLanguage(),options);
   }

   showVoucher( code) {
     var url = "https://elevatortvbackend.appspot.com/v?action=voucher_details&voucher_id="+code;
     fetch(url)
         .then(res => res.json())
         .then(
           (result) => {
             //console.log(JSON.stringify(result));
             if(result["voucher_id"]) {
                console.log("validity_date="+result["validity"]);
                this.setState({loaded:true,title:result["title"],desc:result["desc"],qr_code:result["voucher_id"],terms_conditions_content:result["terms"],validity_date:this.translateDate(result["validity"])});
             }else{
               this.showErrorMessage(result["reason"]);
             }
           });
   }

   trans( msgID) {
     //console.log("trans="+msgID);
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   refreshPage() {
     window.location.reload(false);
   }

   render() {
      //console.log("xxx1 showVoucher");
      return (
        this.state.window==='error_message'?<FullscreenMessage message={this.state.error_message} />:
        !this.state.loaded?<span/>:
        <div style={{margin:"5%",height:'90%',width:'90%',display:"flex",justifyContent:"center"}}>
          <VoucherView qr_code={this.state.qr_code} title={this.state.title} desc={this.state.desc} validity_date={this.state.validity_date} terms_conditions_content={this.state.terms_conditions_content} />
        </div>
      );
   }

}

export default ShowVoucher;
