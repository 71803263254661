import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FullscreenMessage from './FullscreenMessage';
import { FormattedMessage} from 'react-intl';

class VoucherView extends React.Component {

  constructor(props) {
      super(props);
   }

   state = {
     window:'',
     info_message:'',
     canvas_width:300,
     canvas_height:500,
     canvas:'',
     ctx:'',
     fullscreen_message:false,
     image:undefined,
     qr_width:250,
     qr_img:undefined,
   }

   componentDidMount() {
      const canvas1 = this.refs.canvas;
      const ctx1 = canvas1.getContext("2d");
      this.setState({ctx:ctx1, canvas:canvas1});
   }

   componentDidUpdate() {
     this.drawCanvasContent();
   }

   getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
   }

   translateDate( d) {
      var date = new Date(d);
			var options = { year: 'numeric', month: 'long', day: 'numeric', hour:"2-digit", minute:"2-digit" };
			return date.toLocaleString(this.getNavigatorLanguage(),options);
   }

   isNumber(n) {
     return !isNaN(parseFloat(n)) && isFinite(n);
   }

   showFullScreenMessage(message) {
     this.setState({info_message:message,fullscreen_message:true});
   }

   drawCanvasContent() {
      //console.log("drawCanvasContent");
      if(!this.state.ctx) return;
      //console.log("ctx done");
      this.drawQR();
      this.drawTitle();
      this.drawDesc();
      this.drawValidity();
      this.drawCircle();
   }

   drawQR() {
     let imageURL = "https://e1e.tv/q?c="+encodeURIComponent("https://c.e1e.tv/v/"+this.props.qr_code);
     var image = new Image();
     image.crossOrigin="anonymous";
     image.src = imageURL;
     //console.log("ImageURL="+imageURL);

     var ctx = this.state.ctx;
     var imgWidth = this.state.qr_width;
     var startX = (this.state.canvas_width-imgWidth)/2;
     //img.onload = function() {
         //context.drawImage(imageObj, 0, 0, 100, 100 * imageObj.height / imageObj.width)
         //ctx.drawImage(img, startX, startX, 400, 400);
     //}
     image.addEventListener("load",function(){ctx.drawImage(image, startX, startX, imgWidth, imgWidth);},false);
     //downloadedImg.addEventListener("load", this.imageReceived, false);
   }


   drawTitle() {
      var ctx = this.state.ctx;
      ctx.font = "bold 40px Arial";
      ctx.fillStyle = "#3d8704";
      var measure = this.state.ctx.measureText(this.props.title);
      var h = 40;
      var w = measure.width;
      var x = (this.state.canvas_width - w)/2;
      var y = this.state.qr_width + h + 40;
      ctx.fillText(this.props.title, x, y);
   }

   cutTextToArr( txt, maxCharsLine) {
     //console.log("-----> txt ="+txt);
     var res = [];
     var remaining = txt;
     for(var i=0; i<3 && remaining.length>0; i++) {
       var temp0 = remaining.substring(0,maxCharsLine);
       var remaining0 = remaining.substring(maxCharsLine,remaining.length);
       if(temp0.length<maxCharsLine) {
         res[i] = temp0;
         remaining = '';
       }else{
         res[i] = temp0.substring(0,temp0.lastIndexOf(" "));
         remaining = temp0.substring(temp0.lastIndexOf(" ")+1)+remaining0;
       }
     }
     return res;
   }

   drawDesc() {
      var arr = this.cutTextToArr(this.props.desc,25);
      var ctx = this.state.ctx;
      ctx.font = "25px Helvetica";
      ctx.fillStyle = "black";
      var x = (this.state.canvas_width - this.state.qr_width)/2;
      for(var i=0; i<arr.length; i++) {
        var y = this.state.qr_width + 130;
        var measure = this.state.ctx.measureText(arr[i]);
        var w = measure.width;
        var x = (this.state.canvas_width - w)/2;
        ctx.fillText(arr[i], x, y+i*30);
      }
   }

   drawValidity() {
      var ctx = this.state.ctx;
      ctx.font = "bold 20px Arial";
      ctx.fillStyle = "#999999";
      var txt = this.props.validity_date;
      var measure = this.state.ctx.measureText(txt);
      var w = measure.width;
      var x = (this.state.canvas_width - w)/2;
      var y = this.state.canvas_height - 30;
      ctx.fillText(txt, x, y);

      ctx.font = "bold 12px Arial";
      ctx.fillStyle = "#999999";
      var txt = "valid until";//this.trans("valid_until");
      var measure = this.state.ctx.measureText(txt);
      var w = measure.width;
      var x = (this.state.canvas_width - w)/2;
      var y = this.state.canvas_height - 55;
      ctx.fillText(txt, x, y);

   }

   drawCircle() {
     const radius = 15;
     var circles = this.state.canvas_width/radius;
     this.state.ctx.beginPath();
     for(var i=0; i<circles; i++) {
       this.state.ctx.arc(i*2*radius, this.state.canvas_height, radius-5, Math.PI, 2*Math.PI);
     }
     var ctx = this.state.ctx;
     ctx.fillStyle = "white";
     ctx.fill();
   }

   trans( msgID) {
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   showTerms() {
     this.setState({window:'terms_conditions'});
   }

   closeDialog() {
     this.setState({window:''});
   }

   saveCoupon() {
     var base64 = this.state.canvas.toDataURL('image/png');
     var link = document.createElement("a");
     document.body.appendChild(link);
     link.setAttribute("href", base64);
     link.setAttribute("download", "voucher.png");
     link.click();
   }

   render() {
      return (
        <div style={{margin:"5%",height:'90%',width:'90%'}}>
          <Card>
            <CardContent>
                <div style={{ display:'flex', justifyContent:'center' }}>
                  <canvas ref="canvas" width={this.state.canvas_width} height={this.state.canvas_height} style={{border:"0px solid lightgrey", backgroundColor:'#EFE5D5'}} tabIndex="0" />
                </div>
                <div style={{ display:'flex', justifyContent:'center', marginTop:30 }}>
                  <Button onClick={()=>{this.saveCoupon()}} variant='contained' color='success' size="large" fullWidth={true}><Typography variant="h5">{this.trans("save")}</Typography></Button>
                </div>
            </CardContent>
            <CardActions style={{justifyContent:'center'}}>
              <Button onClick={()=>{this.showTerms()}}>{this.trans("terms_conditions")}</Button>
            </CardActions>
          </Card>

          <Dialog open={this.state.window==='terms_conditions'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.trans("terms_conditions")}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{this.props.terms_conditions_content}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeDialog()}} color="primary">{this.trans("close")}</Button>
            </DialogActions>
          </Dialog>

        </div>
      );
   }

}

export default VoucherView;
